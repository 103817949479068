<template>
	<div>
		<v-layout class="">
			<v-flex class="py-0 my-auto d-flex text-right listing-right justify-content-end">
				<template>
					<v-flex class="flex d-flex justify-content-start align-items-center mt-2">
						<h5 class="text-h5 font-weight-bold" style="color: #0d47a1">Total Excel File</h5>
						<v-avatar class="ms-2" color="blue darken-4" size="30">
							<span class="white--text text-h6">{{ totalRows }}</span>
						</v-avatar>
					</v-flex>
					<v-flex class="flex d-flex justify-content-end pr-1 mt-2">
						<div class="mr-3" v-if="false">
							<TextInput
								hide-details
								outlined
								placeholder="Software Type"
								v-model="filter_industry_type"
								class="mt-0"
								append-inner-icon="mdi-magnify"
								v-on:change="getExcelFile"
							>
							</TextInput>
						</div>
						<div class="mr-3" v-if="false">
							<TextInput
								hide-details
								outlined
								placeholder="Software Type"
								v-model="filter_software_type"
								class="mt-0"
								prepend-icon="mdi-magnify"
								v-on:change="getExcelFile"
							>
							</TextInput>
						</div>
						<div class="mr-3">
							<DateRangePicker
								v-on:click:clear="clearFliter"
								hide-details
								v-on:clearDates="dateRangeClear"
								:clear="date_status"
								hide-top-margin
								clearable
								v-model="filter_date"
								:disablePastFrom="Array.isArray(filter_date) ? filter_date[0] : ''"
								v-on:change="getExcelFile($event, 'date')"
							></DateRangePicker>
						</div>
						<template>
							<v-btn
								depressed
								color="white"
								class="red darken-4 ml-2"
								tile
								text
								small
								style="height: 30px; min-width: 0px !important"
								v-on:click="clearFliter"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</template>
						<template>
							<v-btn
								depressed
								:disabled="pageLoading"
								tile
								color="blue darken-4"
								class="text-white ml-2"
								v-on:click="generate_excel"
							>
								Generate
							</v-btn>
						</template>
					</v-flex>
				</template>
			</v-flex>
		</v-layout>
		<template v-if="pageLoading">
			<v-progress-linear height="5" indeterminate color="#f57c00"></v-progress-linear>
		</template>
		<template v-else>
			<v-progress-linear height="5" indeterminate color="white"></v-progress-linear>
		</template>
		<template v-if="excelFileData.length">
			<v-simple-table class="bt-table">
				<thead>
					<tr>
						<th>Date</th>
						<th>Industry Type</th>
						<th>Software Type</th>
						<th>Excel</th>
						<th>Pdf</th>
						<th>Created At</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(row, index) in excelFileData" :key="index">
						<td>
							<v-icon small>mdi-calendar</v-icon>
							<span>{{ row.added_at }}</span>
						</td>
						<td width="250">
							<ShowValue :object="row" object-key="industry_type" label="Industry Type"></ShowValue>
						</td>
						<td width="250">
							<ShowValue :object="row" object-key="software_type" label="Software Type"></ShowValue>
						</td>
						<td width="250">
							<v-icon @click="downloadExcel(row.excel_url)" large color="green">mdi-file-excel</v-icon>
						</td>
						<td width="250">
							<v-icon @click="openExcelPdf(row.pdf_url)" large color="red">mdi-file-pdf</v-icon>
						</td>
						<td>
							<div class="mb-2">
								<v-chip label outlined color="green" text-color="green">{{ row.added_at }}</v-chip>
							</div>
							<div>
								<v-chip pill outlined>
									<v-avatar left>
										<v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg" alt="John"></v-img>
									</v-avatar>
									Super Admin
								</v-chip>
							</div>
						</td>
					</tr>
				</tbody>
			</v-simple-table>
			<v-row class="listing-footer py-2">
				<v-col class="my-auto text-capitalize"
					><p>{{ showingString }}</p>
				</v-col>
				<v-col class="my-auto">
					<v-pagination v-model="currentPage" :length="totalPages" v-on:input="getExcelFile">
					</v-pagination
				></v-col>
			</v-row>
		</template>
		<template v-else>
			<p class="m-0 row-not-found text-center">
				<img
					data-v-f61eafd4=""
					src="http://127.0.0.1:8080/media/error/empty.png"
					class="row-not-found-image"
				/>
				Uhh... There are no excel at the moment.
			</p>
		</template>
		<Excel @success="getExcelFile()" @close="updateDialog = false" :update-dialog="updateDialog">
		</Excel>
	</div>
</template>

<style scoped>
.inline-filter .v-input__control fieldset {
	max-height: 31px !important;
}

.inline-filter .v-input__control .v-input__append-inner {
	margin-top: 2.2px !important;
	margin-bottom: 0 !important;
}
</style>

<script>
import Excel from "@/view/module/excel-file/Create-excel";
import ShowValue from "@/view/components/ShowValue";
import DateRangePicker from "@/view/components/DateRangePicker";
import TextInput from "@/view/components/TextInput";
import { QUERY } from "@/core/services/store/request.module";
import ListingMixin from "@/core/mixins/listing.mixin";
//import { mapGetters } from "vuex";

export default {
	name: "Excel-listing",
	title: "Listing Excel",
	mixins: [ListingMixin],
	props: {
		updatedData: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			page: 1,
			summary_show: false,
			currentPage: 1,
			showingFrom: 0,
			showingString: "",
			totalRows: 0,
			totalPages: 0,
			filter_date: [],
			// filter_software_type: null,
			// filter_industry_type: null,
			export_dialog: false,
			updateDialog: false,
			endpoint: "excel",
			pageLoading: false,
			excelData: null,
			excelFileData: [],
			timeout: null,
			pageTitle: "Here",
			clearDate: false,
			date_status: Number(new Date()),
		};
	},
	methods: {
		dateRangeClear() {
			this.clearDate = false;
		},
		clearFliter() {
			this.date_status = Number(new Date());
			this.filter_date = null;
			(this.defaultFilter = {}),
				this.$nextTick(() => {
					this.getListing();
				});
			this.$router.replace({
				name: "excel",
				query: { t: new Date().getTime() },
				date: [],
			});
		},
		generate_excel() {
			this.updateDialog = true;
		},
		downloadExcel(url) {
			let link = document.createElement("a");
			link.setAttribute("download", "Excel File");
			link.href = url;
			document.body.appendChild(link);
			link.click();
			link.remove();
		},
		openExcelPdf(url) {
			window.open(url, "_blank");
		},
		getExcelFile() {
			const query = {
				per_page: 10,
				page: this.currentPage,
				filter_date: this.filter_date,
				// industry_type: this.filter_software_type,
				// software_type: this.filter_industry_type,
			};
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.pageLoading = true;
				this.$store
					.dispatch(QUERY, { url: "generated-pdfs", data: query })
					.then((data) => {
						this.excelFileData = data.tbody;
						this.totalPages = data.totalPages;
						this.currentPage = data.page;
						this.totalRows = data.totalRows;
						this.showingString = data.showing_String;
					})
					.catch((error) => {
						console.log("error", { error });
					})
					.finally(() => {
						this.pageLoading = false;
					});
			}, 500);
		},
	},
	mounted() {
		this.getExcelFile();
	},
	// computed: {
	// 	...mapGetters(["currentUser"]),
	// },
	components: {
		Excel,
		ShowValue,
		DateRangePicker,
		TextInput,
		//Dialog
	},
};
</script>
<style scoped>
.bt-table .v-data-table__wrapper {
	height: calc(100vh - 300px) !important;
	overflow: auto !important;
}
</style>
